/* You can add global styles to this file, and also import other style files */

@import "scss/material-theme";
@import "scss/variables";
@import "common/scss/global";
@import "common/scss/utils";
@import "common/scss/no-results-message";
@import "common/scss/buttons";
@import "common/scss/input";
@import "common/scss/modal";
@import "common/scss/social-icons";
@import "common/scss/auth-page";
@import "common/scss/perfect-scrollbar-theme";
@import "common/scss/appearance-editor-select-box";
@import "scss/webkit-scrollbar";
@import "scss/perfect-scrollbar";
@import "scss/web-player-overlay-theme";
@import "scss/round-close-button";
@import "app/web-player/context-menu/context-menu";
@import "scss/media-modal-autocomplete";

account-settings {
  padding: 15px;
}