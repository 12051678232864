@import "variables";

web-player, .web-player-theme {
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $wp-bg-color-300;
    border-radius: 3px;

    &:hover {
      background-color: $wp-bg-color-200;
    }
  }
}